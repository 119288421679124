<template>
  <section class="assets-one-level">
    <div class="top-bar bg-white" style="justify-content: left">
      <div style="margin-right: 10px">
        <el-input
          size="small"
          style="width: 200px"
          v-model="formSearch.keyword"
          placeholder="请输入资产类别或设备类型"
        />
      </div>
      <el-button type="primary" size="small" @click="handleSearch"
        >查询</el-button
      >
      <el-button size="small" @click="handleReset">重置</el-button>
      <el-button
        type="primary"
        size="small"
        icon="el-icon-plus"
        @click="openAdd"
        >新建入库</el-button
      >
    </div>
    <div style="padding: 25px; background-color: #ffffff; border-radius: 10px">
      <r-e-table
        class="bg-white"
        ref="rentTableRef"
        :dataRequest="getAssetsAllocateList"
        :columns="tableColumn"
        :query="formSearch"
        :height="800"
      >
        <template slot="empty">
          <el-empty />
        </template>
        <el-table-column slot="toolbar" label="操作" width="150">
          <template slot-scope="{ row }">
            <div class="table-tools">
              <span class="table-btn" @click="lookDetail(row)">详情</span>
            </div>
          </template>
        </el-table-column>
      </r-e-table>
    </div>

    <dialog-add-warehousing
      ref="dialogAddWarehousing"
      @handleSearch="handleSearch"
    />
  </section>
</template>

<script>
import { assetsOneLevelColumn } from '@/views/property-management/assets-management/data'
import { getAssetsTypeList } from '@/api/assets'

export default {
  name: 'assets-one-level',
  components: {
    dialogAddWarehousing: () => import('./components/dialog-add-warehousing')
  },
  data () {
    return {
      tableColumn: assetsOneLevelColumn,
      formSearch: {
        keyword: null
      }
    }
  },
  methods: {
    getAssetsAllocateList (params) {
      return getAssetsTypeList(params)
    },

    handleSearch () {
      this.$refs['rentTableRef'].pNumber = 1
      this.$refs['rentTableRef'].getTableData()
    },

    handleReset () {
      this.formSearch.keyword = null
      Promise.resolve(this.formSearch).then(() => this.handleSearch())
    },

    lookDetail (data) {
      const { assetType } = data
      this.$router.push({ name: 'assets-one-level', query: { assetType } })
    },

    openAdd () {
      this.$refs['dialogAddWarehousing'].openDialog()
    }
  }
}
</script>

<style lang="scss" scoped>
.assets-one-level {
  padding: VH(15px) VW(15px);

  .top-bar {
    display: flex;
    margin: VH(10px) 0 VH(10px);
    padding: VH(15px) VW(15px);
    justify-content: center;

    .el-radio-button.is-active {
      box-shadow: 0 0 10px #ddd inset;
    }
  }

  /deep/ .r-e-table {
    .el-table .cell {
      white-space: nowrap;
      text-align: center;
    }
  }

  .title {
    height: VH(50px);
    line-height: VH(50px);
    color: #666;
    padding-left: VW(10px);
    position: relative;

    &::before {
      width: 5px;
      height: 40%;
      background-color: #5c84fb;
      content: '';
      position: absolute;
      left: 0;
      top: 30%;
    }
  }
}
</style>
